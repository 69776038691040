import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import './testimonial-slider.scss';

const TestimonialSlider = (props) => {
  const { settings, children } = props;
  return (
    <div className="on-testimonial-slider">
      <div className="content">
        <Slider {...settings}>{children}</Slider>
      </div>
    </div>
  );
};

TestimonialSlider.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.object,
};

TestimonialSlider.defaultProps = { children: null, settings: {} };
export default memo(TestimonialSlider);

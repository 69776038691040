import cs from 'classnames';
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import './client-faq-container.scss';

import { ChevronIcon } from '@components';
import useSiteMetadata from '../../../api/graphql/siteMetadata';

const ClientFaqContainer = (props) => {
  const { openModalHandler } = props;
  const { title } = useSiteMetadata();

  const [openFAQ, setOpenFAQ] = useState('');

  const toggleFAQ = (value) => setOpenFAQ(openFAQ === value ? '' : value);

  return (
    <div className={cs('on-client-faq')}>
      <div className="faq-component">
        <h2>FAQs</h2>
        <div className="faq">
          <div className="accordion">
            <div className="accordion-top" onClick={() => toggleFAQ('howToGetStarted')}>
              How do I get started with HomeLight Trade-In?
              <div className="expand-icon">
                <ChevronIcon className={`chevron-icon-component ${openFAQ === 'howToGetStarted' ? '' : 'right'}`} />
              </div>
            </div>
            <div className={`accordion-details ${openFAQ === 'howToGetStarted' ? 'details' : 'details-hidden'}`}>
              <p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    openModalHandler(true);
                  }}>
                  Apply here.
                </a>{' '}
                You’ll work with {title} to fill out your HomeLight Trade-In application and a HomeLight representative
                will be in touch to discuss your home buying goals.
              </p>
              <p>
                You can also get a head start on the home buying process by completing your mortgage application on{' '}
                <a href="https://www.homelight.com/home-loans" target="_blank" rel="noreferrer noopener">
                  HomeLight Home Loans.
                </a>
              </p>
            </div>
          </div>
          <div className="accordion">
            <div className="accordion-top" onClick={() => toggleFAQ('whatDoesItCost')}>
              What does it cost to use HomeLight Trade-In?
              <div className="expand-icon">
                <ChevronIcon className={`chevron-icon-component ${openFAQ === 'whatDoesItCost' ? '' : 'right'}`} />
              </div>
            </div>
            <div className={`accordion-details ${openFAQ === 'whatDoesItCost' ? 'details' : 'details-hidden'}`}>
              <p>
                HomeLight charges a fee to purchase, own, and sell your current home. The fee is dependent on your
                market, the lender you use, and the number of days HomeLight owns your home. All fees are calculated off
                of the final selling price of your current home. Additional costs may apply based on local tax laws.
              </p>
              <p>
                Additional costs may apply based on local tax laws.
                <br />
                <br />
                California and Colorado fee schedule:
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>First 30 days HomeLight owns the home</th>
                      <th>Every 30 days thereafter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>When using HomeLight Home Loans</td>
                      <td>1.0%</td>
                      <td>0.5%, prorated daily</td>
                    </tr>
                    <tr>
                      <td>When using another lender</td>
                      <td>3.0%</td>
                      <td>0.5%, prorated daily</td>
                    </tr>
                  </tbody>
                </table>
              </p>
              <br />
              <p>
                Arizona, Florida, and Texas fee schedule:
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>First 30 days HomeLight owns the home</th>
                      <th>Every 30 days thereafter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>When using HomeLight Home Loans</td>
                      <td>1.0%</td>
                      <td>1.0%, prorated daily</td>
                    </tr>
                    <tr>
                      <td>When using another lender</td>
                      <td>3.0%</td>
                      <td>1.0%, prorated daily</td>
                    </tr>
                  </tbody>
                </table>
              </p>

              <p>
                Email <a href="mailto:cashclose@homelight.com">cashclose@homelight.com</a> for more details.
              </p>
            </div>
          </div>
          <div className="accordion">
            <div className="accordion-top" onClick={() => toggleFAQ('howIsMyHomeSold')}>
              How is my home sold with HomeLight Trade-In?
              <div className="expand-icon">
                <ChevronIcon className={`chevron-icon-component ${openFAQ === 'howIsMyHomeSold' ? '' : 'right'}`} />
              </div>
            </div>
            <div className={`accordion-details ${openFAQ === 'howIsMyHomeSold' ? 'details' : 'details-hidden'}`}>
              <p>
                {title} and{' '}
                <a href="https://www.homelight.com/" target="_blank" rel="noreferrer noopener">
                  HomeLight
                </a>{' '}
                work together throughout the listing and sale process. Our goal is for you to get into the home of your
                dreams while receiving full market value for your current home. If your home sells for more than the
                price we paid for it, we’ll give you the additional cash minus selling costs and program fees.*
              </p>
            </div>
          </div>
          <div className="accordion">
            <div className="accordion-top" onClick={() => toggleFAQ('stillWithAgents')}>
              Can I still work with {title}?
              <div className="expand-icon">
                <ChevronIcon className={`chevron-icon-component ${openFAQ === 'stillWithAgents' ? '' : 'right'}`} />
              </div>
            </div>
            <div className={`accordion-details ${openFAQ === 'stillWithAgents' ? 'details' : 'details-hidden'}`}>
              <p>
                Yes! HomeLight is proud to work with {title} and they’ll give you top notch council and guidance
                throughout the process.
              </p>
            </div>
          </div>
          <div className="accordion">
            <div className="accordion-top" onClick={() => toggleFAQ('isHomeLightAvailable')}>
              Is HomeLight Trade-In available for my house?
              <div className="expand-icon">
                <ChevronIcon
                  className={`chevron-icon-component ${openFAQ === 'isHomeLightAvailable' ? '' : 'right'}`}
                />
              </div>
            </div>
            <div className={`accordion-details ${openFAQ === 'isHomeLightAvailable' ? 'details' : 'details-hidden'}`}>
              <p>
                <a href="https://www.homelight.com/trade-in" target="_blank" rel="noreferrer noopener">
                  HomeLight Trade-In
                </a>{' '}
                is currently available in Arizona, California, Colorado, Florida, and Texas but things like where you’re
                located, the size, and condition of your will determine eligibility. Enter your information{' '}
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    openModalHandler(true);
                  }}>
                  here
                </a>{' '}
                to be notified when HomeLight Trade-In launches in your area.
              </p>
            </div>
          </div>
        </div>
        <div className="smallest-text">
          <p>
            *The fee is dependent on your market, the lender you use, and the number of days we own your home. See FAQ
            section for details.
          </p>
          {/*
            <p>
              **Based on Chauner, Bailey. “How to Win a Real Estate Bidding War.” Redfin, 22 Nov. 2019,
               www.redfin.com/blog/win-bidding-war-on-house/
            </p>
            */}
        </div>
      </div>
    </div>
  );
};

ClientFaqContainer.propTypes = {
  openModalHandler: PropTypes.func,
};

ClientFaqContainer.defaultProps = { openModalHandler: () => {} };

export default memo(ClientFaqContainer);

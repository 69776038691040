import cs from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './why-homelight.scss';

const WhyHomelight = (props) => {
  const { children } = props;
  return <div className={cs('on-why-homelight')}>{children}</div>;
};

WhyHomelight.propTypes = {
  children: PropTypes.node,
};

WhyHomelight.defaultProps = { children: null };

export default memo(WhyHomelight);

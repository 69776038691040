import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './slide-template.scss';

const SlideTemplate = (props) => {
  const { id, key, imgSource, imgAlt, quote, link, author } = props;
  return (
    <div
      data-index={id}
      key={key}
      className="slick-slide slick-active slick-current testimonial-slide"
      tabIndex="-1"
      aria-hidden="false">
      <div>
        <div tabIndex="-1" className="testimonial-slide-inner">
          <div className="img-wrap">
            <picture>
              <source type="image/webp" srcSet={imgSource.webp} />
              <source type="image/jpg" srcSet={imgSource.jpg} />
              <img className=" lazyloaded" data-src={imgSource.jpg} alt={imgAlt} src={imgSource.jpg} />
            </picture>
          </div>
          <blockquote>
            <p>
              <q>{quote}</q>
              <br />
              {link && <a href={link.url}>{link.text}</a>}
            </p>
            <cite>
              <strong>{author.displayName}</strong>
              <span>{author.description}</span>
            </cite>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

SlideTemplate.propTypes = {
  id: PropTypes.number,
  key: PropTypes.any,
  imgSource: PropTypes.shape({
    webp: PropTypes.string,
    jpg: PropTypes.string,
  }),
  imgAlt: PropTypes.string,
  quote: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
  }),
  author: PropTypes.shape({
    displayName: PropTypes.string,
    description: PropTypes.string,
  }),
};

SlideTemplate.defaultProps = {
  id: 0,
  key: 0,
  imgSource: null,
  imgAlt: '',
  quote: '',
  link: '',
  author: {
    displayName: '',
    description: '',
  },
};

export default memo(SlideTemplate);

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './how-it-works.scss';

const HowItWorks = (props) => {
  const { title, children } = props;

  return (
    <div className="on-how-it-works">
      <div className="cash-offer-process section">
        <div className="content">
          <h2>{title}</h2>
          <div className="process-step">{children}</div>
        </div>
      </div>
    </div>
  );
};

HowItWorks.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

HowItWorks.defaultProps = { children: null, title: '' };

export default memo(HowItWorks);

import cs from 'classnames';
import React, { memo } from 'react';
import useSiteMetadata from '../../../api/graphql/siteMetadata';
import './testimonials-container.scss';

const TestimonialsContainer = () => {
  const { title } = useSiteMetadata();
  return (
    <div className={cs('on-testimonials')}>
      <div className="section testimonials">
        <div className="section-media section-video">
          <div className="content">
            <div className="row">
              <div className="col-xs-12 col-sm-6 xs-first">
                <picture>
                  <img
                    alt="Testimonial Top picture"
                    src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/testimonials/kelly-baldwin-46ae8de561b030e62a9d03764e37189d9105bfdbc61b957fef59f8b731e6bdde.jpg"
                  />
                </picture>
              </div>
              <div className="col-xs-12 col-sm-6">
                <h2>Take their word for it</h2>
                <div className="inner">
                  <blockquote>
                    <q>
                      {'My clients have been thrilled with Homelight. '}
                      <a href="https://www.homelight.com/cash-offer" target="_blank" rel="noreferrer noopener">
                        HomeLight Cash Offer
                      </a>
                      {' made their purchase a reality when they thought it wouldn\'t have been possible.'}
                    </q>
                    <br />
                    <br />
                    <cite>
                      <strong>Kelly Baldwin</strong>
                      <br />
                      <span>HomeLight Cash Offer Certified Agent</span>
                    </cite>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={cs('testimonial-slider-container')}>
          <div className="section-media section-video">
            <div className="content">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <h2>Already own a home and looking to purchase a new one?</h2>
                  <div className="inner">
                    <br />
                    <p>Now you can buy before you sell with HomeLight Trade-In™.</p>
                    <br />
                    <p>
                      We buy your current home so you can avoid the stress, risk, and hassle of buying and selling at
                      the same time.
                    </p>
                    <br />
                    <a className="button testimonial-button" href="https://www.homelight.com/testimonials">
                      Learn more
                    </a>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 xs-first">
                  <picture>
                    <img
                      alt="Testimonial Bottom picture"
                      src="https://d1xt9s86fx9r45.cloudfront.net/assets/hl-production/assets/v4/cash_close/trade-in-desktop-2x-b69382335c39a98e13dc3effbc0e34a81285f5b1256086b5bfe354e825752c64.jpg"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TestimonialsContainer);

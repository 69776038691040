import React, { memo } from 'react';
import './how-it-works-container.scss';

import { HowItWorks, Step } from '@components';

import useSiteMetadata from '../../../api/graphql/siteMetadata';

import { ContractIcon, MoneyHouseIcon, KeysIcon, MoneyIcon } from '../../../images/icons';

const HowItWorksContainer = () => {
  const { title } = useSiteMetadata();

  const children = (
    <>
      <Step
        icon={<ContractIcon className="document-icon" />}
        title="Get HomeLight's purchase price"
        description={`HomeLight will work with ${title} to make a competitive cash offer on your current home. That's the price
        HomeLight will pay you for your home as soon as you're ready to move into your new home.`}
      />
      <Step
        icon={<MoneyHouseIcon className="dream-home-money-icon" />}
        title="Make a strong offer on your new home"
        description="When you’ve found the home of your dreams, you can make an offer knowing HomeLight will buy your current
        house. This means you’re more likely to close and can do so on your own timeline."
      />
      <Step
        icon={<KeysIcon className="keys-icon" />}
        title="Move in on your schedule"
        description="You control when you move. Once your offer is accepted, everything comes together in days. You won’t have to
        race against the clock, move into a temporary residence, or carry two mortgages. HomeLight buys your current
        home at the HomeLight Purchase Price and you get the cash to close on your dream home whenever you’re ready."
      />
      <Step
        icon={<MoneyIcon className="money-icon" />}
        title="Get HomeLight's purchase price"
        description={`${title} goes to work and lists your current home for sale. If your home sells for more than the HomeLight
          Purchase Price you get the additional cash minus selling costs and program fees.*`}
      />
    </>
  );

  return <HowItWorks title="How HomeLight Trade-In works">{children}</HowItWorks>;
};

export default memo(HowItWorksContainer);

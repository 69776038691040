import cs from 'classnames';
import React, { memo } from 'react';
import { CallToAction } from '@components';
import './call-to-action-container.scss';

const CallToActionContainer = (props) => (
  <div className={cs('on-call-to-action')}>
    <CallToAction {...props} title="Curious how much we'll offer for your home?" text="Get your Offer" />
  </div>
);

export default memo(CallToActionContainer);

export const menuItems = [
  {
    id: 1,
    htmlId: 'menu-home',
    displayName: 'Home', // TODO: I18n this
    url: 'https://www.dfwrealestateinfo.com/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: false,
  },
  {
    id: 2,
    htmlId: 'menu-meet',
    displayName: 'Buy', // TODO: I18n this
    url: '#',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 201,
        htmlId: 'menu-listings-active',
        displayName: 'All Listings', // TODO: I18n this
        url: 'https://www.dfwrealestateinfo.com/listing',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 202,
        htmlId: 'menu-listings-new',
        displayName: 'Sold Listings', // TODO: I18n this
        url: 'https://www.dfwrealestateinfo.com/sold-listing',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 203,
        htmlId: 'menu-listings-weekend',
        displayName: 'Featured Listings', // TODO: I18n this
        url: 'https://www.dfwrealestateinfo.com/featured-listing',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 3,
    htmlId: 'menu-listings',
    displayName: 'Sell', // TODO: I18n this
    url: 'https://www.dfwrealestateinfo.com/sell',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 4,
    htmlId: 'menu-listings',
    displayName: 'Home Valuation', // TODO: I18n this
    url: 'https://www.dfwrealestateinfo.com/evaluation',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],

    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 5,
    htmlId: 'menu-buyers',
    displayName: 'Cities', // TODO: I18n this
    url: '#',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      { displayName: 'Aledo', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079109/Aledo' },
      { displayName: 'Alvarado', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079110/Alvarado' },
      { displayName: 'Arlington', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079111/Arlington' },
      { displayName: 'Azle', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079112/Azle' },
      { displayName: 'Bedford', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079113/Bedford' },
      { displayName: 'Benbrook', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079114/Benbrook' },
      { displayName: 'Burleson', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079115/Burleson' },
      { displayName: 'Cedar Hill', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079116/Cedar-Hill' },
      { displayName: 'Cleburne', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079117/Cleburne' },
      { displayName: 'Colleyville', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079118/Colleyville' },
      { displayName: 'Crowley', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079119/Crowley' },
      { displayName: 'Dallas', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079120/Dallas' },
      {
        displayName: 'Dalworthington Gardens',
        url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079121/Dalworthington-Gardens',
      },
      { displayName: 'Desoto', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079122/Desoto' },
      { displayName: 'Duncanville', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079123/Duncanville' },
      { displayName: 'Euless', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079124/Euless' },
      { displayName: 'Fort Worth', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079125/Fort-Worth' },
      { displayName: 'Grand Prairie', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079126/Grand-Prairie' },
      { displayName: 'Grapevine', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079127/Grapevine' },
      { displayName: 'Haslet', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079128/Haslet' },
      { displayName: 'Hurst', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079129/Hurst' },
      { displayName: 'Irving', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079130/Irving' },
      { displayName: 'Joshua', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079131/Joshua' },
      { displayName: 'Kennedale', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079132/Kennedale' },
      { displayName: 'Keller', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079133/Keller' },
      { displayName: 'Lancaster', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079134/Lancaster' },
      { displayName: 'Mansfield', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079135/Mansfield' },
      { displayName: 'Midlothian', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079136/Midlothian' },
      {
        displayName: 'North Richland Hills',
        url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079137/North-Richland-Hills',
      },
      { displayName: 'Pantego', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079138/Pantego' },
      { displayName: 'Red Oak', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079139/Red-Oak' },
      { displayName: 'Roanoke', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079140/Roanoke' },
      { displayName: 'Saginaw', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079141/Saginaw' },
      { displayName: 'Southlake', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079142/Southlake' },
      { displayName: 'Venus', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079143/Venus' },
      { displayName: 'Waxahachie', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079144/Waxahachie' },
      { displayName: 'Weatherford', url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079145/Weatherford' },
      {
        displayName: 'White Settlement',
        url: 'https://www.dfwrealestateinfo.com/neighborhoods/12079146/White-Settlement',
      },
    ].map(({ url, displayName }, i) => ({
      id: 501 + i,
      htmlId: 'menu-community-lafayette',
      displayName,
      url,
      // ariaLabel: "", // TODO: a11y
      className: 'nav-submenu-item communities-menu',
      mobileClassName: 'mobile-nav-submenu-item communities-menu',
      children: [],
      displayOnMobile: true,
      displayOnDesktop: true,
    })),
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 6,
    htmlId: 'menu-sellers',
    displayName: 'Blogs', // TODO: I18n this
    url: 'https://www.dfwrealestateinfo.com/blog',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 7,
    htmlId: 'menu-for-lease',
    displayName: 'Reviews', // TODO: I18n this
    url: 'https://www.zillow.com/profile/DonLawyer/',
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 8,
    htmlId: 'menu-communities',
    displayName: 'About', // TODO: I18n this
    url: 'https://www.dfwrealestateinfo.com/about',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
];

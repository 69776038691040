import cs from 'classnames';
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import './client-faq-container.scss';

import { ChevronIcon } from '@components';
import useSiteMetadata from '../../../api/graphql/siteMetadata';

const ClientFaqContainer = (props) => {
  const { openModalHandler } = props;
  const { title } = useSiteMetadata();

  const [openFAQ, setOpenFAQ] = useState('');

  const toggleFAQ = (value) => setOpenFAQ(openFAQ === value ? '' : value);

  return (
    <div className={cs('on-client-faq')}>
      <div className="faq-component">
        <h2>FAQs</h2>
        <div className="faq">
          <div className="accordion">
            <div className="accordion-top" onClick={() => toggleFAQ('howToGetStarted')}>
              How do I get started with HomeLight Cash Offer?
              <div className="expand-icon">
                <ChevronIcon className={`chevron-icon-component ${openFAQ === 'howToGetStarted' ? '' : 'right'}`} />
              </div>
            </div>
            <div className={`accordion-details ${openFAQ === 'howToGetStarted' ? 'details' : 'details-hidden'}`}>
              <p>
                <a
                  href="https://www.homelight.com/cash-close/trade-in-quiz#/qaas=0/"
                  target="_blank"
                  rel="noreferrer noopener">
                  Apply here.
                </a>{' '}
                You’ll work with {title} to fill out your HomeLight Cash Offer application, a HomeLight representative
                will be in touch to discuss your home buying goals. <br />
              </p>
              <p>
                You can also get a head start on the home buying process by completing your mortgage application through{' '}
                <a href="https://www.homelight.com/home-loans" target="_blank" rel="noreferrer noopener">
                  HomeLight Home Loans.
                </a>
              </p>
            </div>
          </div>
          <div className="accordion">
            <div className="accordion-top" onClick={() => toggleFAQ('whatDoesItCost')}>
              What does it cost to use HomeLight Cash Offer?
              <div className="expand-icon">
                <ChevronIcon className={`chevron-icon-component ${openFAQ === 'whatDoesItCost' ? '' : 'right'}`} />
              </div>
            </div>
            <div className={`accordion-details ${openFAQ === 'whatDoesItCost' ? 'details' : 'details-hidden'}`}>
              <p>
                HomeLight charges a fee to use our funds to make an all-cash offer and purchase the home on your behalf.
                The fee is dependent on your market, the lender you use, and the number of days HomeLight owns your
                home. All fees are calculated off of the home purchase price.
              </p>
              <p>
                Additional costs may apply based on local tax laws.
                <br />
                <br />
                Arizona, California, Colorado, and Texas fee schedule:
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>First 30 days HomeLight owns the home</th>
                      <th>Every 30 days thereafter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>When using HomeLight Home Loans</td>
                      <td>1.0%</td>
                      <td>0.5%, prorated daily</td>
                    </tr>
                    <tr>
                      <td>When using another lender</td>
                      <td>3.0%</td>
                      <td>0.5%, prorated daily</td>
                    </tr>
                  </tbody>
                </table>
              </p>
              <br />
              <p>
                Florida fee schedule:
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>First 30 days HomeLight owns the home</th>
                      <th>Every 30 days thereafter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>When using HomeLight Home Loans</td>
                      <td>1.5%</td>
                      <td>0.5%, prorated daily</td>
                    </tr>
                    <tr>
                      <td>When using another lender</td>
                      <td>3.0%</td>
                      <td>0.5%, prorated daily</td>
                    </tr>
                  </tbody>
                </table>
              </p>

              <p>
                Email <a href="mailto:cashclose@homelight.com">cashclose@homelight.com</a> for more details.
              </p>
            </div>
          </div>
          <div className="accordion">
            <div className="accordion-top" onClick={() => toggleFAQ('stillWithAgents')}>
              Can I still work with {title}?
              <div className="expand-icon">
                <ChevronIcon className={`chevron-icon-component ${openFAQ === 'stillWithAgents' ? '' : 'right'}`} />
              </div>
            </div>
            <div className={`accordion-details ${openFAQ === 'stillWithAgents' ? 'details' : 'details-hidden'}`}>
              <p>
                {title} and HomeLight work together throughout the buying process to help you get the home of your
                dreams.
              </p>
            </div>
          </div>
          <div className="accordion">
            <div className="accordion-top" onClick={() => toggleFAQ('isHomeLightAvailable')}>
              Is HomeLight Cash Offer available in my area?
              <div className="expand-icon">
                <ChevronIcon
                  className={`chevron-icon-component ${openFAQ === 'isHomeLightAvailable' ? '' : 'right'}`}
                />
              </div>
            </div>
            <div className={`accordion-details ${openFAQ === 'isHomeLightAvailable' ? 'details' : 'details-hidden'}`}>
              <p>
                <a
                  href="https://www.homelight.com/cash-close/cash-offer-quiz#/qaas=0/"
                  target="_blank"
                  rel="noreferrer noopener">
                  HomeLight Cash Offer
                </a>{' '}
                is currently available in Arizona, California, Colorado, Texas, and Florida. Enter your information{' '}
                <a
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    openModalHandler(true);
                  }}>
                  here
                </a>{' '}
                to be notified when HomeLight Cash Offer launches in your area.
              </p>
            </div>
          </div>
        </div>
        <div className="smallest-text">
          <p>
            *Based on Chauner, Bailey. “How to Win a Real Estate Bidding War.” Redfin, 22 Nov. 2019,
            www.redfin.com/blog/win-bidding-war-on-house/
          </p>
          <p>
            **The fee is dependent on your market, the lender you use, and the number of days HomeLight owns your home.
            See FAQ section for details.
          </p>
        </div>
      </div>
    </div>
  );
};

ClientFaqContainer.propTypes = {
  openModalHandler: PropTypes.func,
};

ClientFaqContainer.defaultProps = { openModalHandler: () => {} };

export default memo(ClientFaqContainer);

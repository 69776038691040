import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  HeaderContainer,
  FooterContainer,
  HeroContainer,
  HowItWorksContainer,
  PricingTableContainer,
  CallToActionContainer,
  TestimonialsContainer,
  WhyHomelightContainer,
  ClientFaqContainer,
  ContactModalContainer,
} from '@containersCashOffer';

import { submitContactForm } from '../../api/contact-form/contact-api';
import { SITE_TYPE } from '../../utils/site-type-validator';

import '../common.scss';
import './cash-offer-layout.scss';

const Layout = (props) => {
  const { children, location } = props;
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [pricingTableVisible, setPricingTableVisible] = useState(false);

  useEffect(() => {
    if (contactModalOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [contactModalOpen]);

  return (
    <div className="layout-container">
      <HeaderContainer location={location} />
      <div className="on-main-container">
        <ContactModalContainer
          isOpen={contactModalOpen}
          openModalHandler={setContactModalOpen}
          submitContactForm={submitContactForm}
          location={location}
          siteType={SITE_TYPE.CASH_OFFER}
        />
        <HeroContainer openModalHandler={setContactModalOpen} />
        <HowItWorksContainer />
        {pricingTableVisible && <PricingTableContainer />}
        <CallToActionContainer openModalHandler={setContactModalOpen} />
        <TestimonialsContainer />
        <WhyHomelightContainer />
        <ClientFaqContainer openModalHandler={setContactModalOpen} />
        {/* {children} */}
      </div>
      <FooterContainer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

Layout.defaultProps = { children: null, location: null };

export default memo(Layout);

import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import useSiteMetadata from '../../../api/graphql/siteMetadata';
import { formStatus } from '../../../enums/form-status';

const ContactForm = (props) => {
  const {
    setSentStatus,
    submitContactForm,
    siteType,
  } = props;

  const query = queryString.parse(window.location.search);

  const { title: site } = useSiteMetadata();
  const [termsAccepted, setTermsAccepted] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const form = useRef(null);

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
  };

  const getFormData = () => {
    const {
      utm_medium: utmMedium = '',
      utm_source: utmSource = '',
      utm_content: utmContent = '',
      utm_term: utmTerm = '',
      utm_campaign: utmCampaign = '',
    } = query || {};

    return {
      Firstname: firstName,
      Lastname: lastName,
      Phone: phone,
      Email: email,
      Site: site,
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_content: utmContent,
      utm_term: utmTerm,
      utm_campaign: utmCampaign,
      type: siteType,
    };
  };

  const validateData = () => form.current.reportValidity();

  const submitForm = () => {
    const data = getFormData();
    const validated = validateData();
    if (validated) {
      setSentStatus(formStatus.SUBMITTING);
      setTimeout(() => {
        submitContactForm(data)
          .then((res) => {
            if (res.filter((r) => r.status !== 200).length > 0) {
              setSentStatus(formStatus.ERROR);
            } else {
              setSentStatus(formStatus.SUCCESS);
              clearForm();
            }
          })
          .catch((err) => {
            setSentStatus(formStatus.ERROR);
            console.log(err);
          });
      }, 1000);
    }
  };

  return (
    <div className="modal-body">
      <div className="modal-title">Contact Us</div>
      <br />
      <div>
        <section className="component-demo generic-form-demo" id="cash-close-signup-form">
          <form ref={form} className="generic-form">
            <div className="row">
              <div className="col-12 col-md-6">
                <label htmlFor="input-first-name">First name</label>
                <input
                  id="input-first-name"
                  className="first-name-input"
                  type="text"
                  placeholder="Enter your first name"
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="input-last-name">Last name</label>
                <input
                  id="input-last-name"
                  className="last-name-input"
                  type="text"
                  placeholder="Enter your last name"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="input-email">Email</label>
                <input
                  id="input-email"
                  className="email-input"
                  type="email"
                  placeholder="Enter your email"
                  required
                  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6">
                <label htmlFor="input-phone">Phone number</label>
                <input
                  id="input-phone"
                  className="phone-input"
                  type="tel"
                  placeholder="Enter your phone number"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-12">
                <input
                  id="input-terms"
                  type="checkbox"
                  checked={termsAccepted}
                  onClick={() => setTermsAccepted(!termsAccepted)}
                />
                <label htmlFor="input-terms" className="terms-label">
                  I have read and accept <a href="https://www.homelight.com/terms">Terms and Conditions</a>.
                </label>
              </div>
            </div>
          </form>
          <button
            type="button"
            disabled={!termsAccepted}
            className="button agent-intro-complete-btn"
            id="contact-form-submit-btn"
            data-submit="true"
            onClick={submitForm}>
            Submit
          </button>
        </section>
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.any,
    search: PropTypes.string,
  }),
  setSentStatus: PropTypes.func,
  submitContactForm: PropTypes.func,
  siteType: PropTypes.string,
};

ContactForm.defaultProps = {
  location: { pathname: '', search: '' },
  setSentStatus: () => {},
  submitContactForm: () => {},
  siteType: '',
};

export default memo(ContactForm);

import React, { memo } from 'react';
import { WhyHomelight } from '@components';
import useSiteMetadata from '../../../api/graphql/siteMetadata';

import './why-homelight-container.scss';

const WhyHomelightContainer = () => {
  const { title } = useSiteMetadata();

  const children = (
    <div className="section company-intro">
      <div className="content">
        <div className="heading">
          <h2>Why HomeLight?</h2>
          <p>HomeLight and {title} are changing the way we transact real estate.</p>
        </div>
        <div className="text-wrap">
          <p>
            HomeLight’s mission is to empower people to make smarter decisions during one of life’s most important
            moments: buying or selling a home.
          </p>
          <p>
            They build data-driven and innovative products that give more control to buyers and sellers while reducing
            risk and uncertainty.
          </p>
          <p>HomeLight has helped over 820,700 clients and has an A+ rating with the Better Business Bureau.</p>
        </div>
        <a className="link" href="https://www.homelight.com/testimonials" rel="noreferrer noopener" target="_blank">
          Read real reviews from our customers
        </a>
      </div>
    </div>
  );

  return <WhyHomelight>{children}</WhyHomelight>;
};

export default memo(WhyHomelightContainer);

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './step.scss';

const Step = (props) => {
  const { icon, title, description } = props;
  return (
    <div className="on-step">
      <div className="icon">{icon}</div>
      <div className="desc">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

Step.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

Step.defaultProps = {
  icon: null,
  title: '',
  description: '',
};

export default memo(Step);

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './call-to-action.scss';

const CallToAction = (props) => {
  const { openModalHandler, title, text, link } = props;
  let callToActionButton;
  if (link) {
    callToActionButton = (
      <a className="button" href={link}>
        {text}
      </a>
    );
  } else {
    callToActionButton = (
      <a className="button" onClick={() => openModalHandler(true)}>
        {text}
      </a>
    );
  }

  return (
    <div className="section-cta section">
      <div className="content">
        <h2>{title}</h2>
        {callToActionButton}
      </div>
    </div>
  );
};

CallToAction.propTypes = {
  openModalHandler: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
};

CallToAction.defaultProps = { openModalHandler: () => {}, title: '', text: '', link: null };

export default memo(CallToAction);

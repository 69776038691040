import cs from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { CallToAction } from '@components';
import './call-to-action-container.scss';

const CallToActionContainer = (props) => {
  const { openModalHandler } = props;

  return (
    <div className={cs('on-call-to-action')}>
      <CallToAction
        {...props}
        title="Ready to use our cash to make an all-cash offer?"
        text="Start here"
        onClick={() => openModalHandler(true)}
      />
    </div>
  );
};

CallToActionContainer.propTypes = {
  openModalHandler: PropTypes.func,
};

CallToActionContainer.defaultProps = { openModalHandler: () => {} };

export default memo(CallToActionContainer);

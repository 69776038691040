import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Footer } from '@components';
import './footer-container.scss';

const FooterContainer = (props) => {
  const { children } = props;

  return <Footer {...props}>{children}</Footer>;
};

FooterContainer.propTypes = {
  children: PropTypes.node,
};

FooterContainer.defaultProps = {
  children: <>Footer Content</>,
};

export default memo(FooterContainer);

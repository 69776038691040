import React, { memo } from 'react';
import PropTypes from 'prop-types';

const AwardCheckIcon = (props) => {
  const { className } = props;
  return (
    <svg className={className} viewBox="0 0 96 96">
      <g id="Layer_2" data-name="Layer 2">
        <g id="approved">
          <circle fill="#e8eef9" cx="48" cy="48" r="48" />
          <polygon fill="#46b6ff" points="48.32 60.15 63.36 56.12 70.19 81.64 61.39 78.87 55.16 85.67 48.32 60.15" />
          <path
            fill="#e8eef9"
            d="M62.65,57.34l6.11,22.8-7.67-2.42-5.44,5.93-6.11-22.8,13.11-3.51m1.41-2.45-1.93.52L49,58.92l-1.93.52.52,1.93,6.11,22.79.94,3.52L57.12,85l4.58-5,6.46,2,3.47,1.1-.94-3.52-6.11-22.8-.52-1.93Z"
          />
          <polygon fill="#46b6ff" points="34.61 78.87 25.81 81.64 32.64 56.12 47.68 60.15 40.84 85.67 34.61 78.87" />
          <path
            fill="#e8eef9"
            d="M33.35,57.34l13.11,3.51-6.11,22.8-5.44-5.93-7.67,2.42,6.11-22.8m-1.41-2.45-.52,1.93-6.11,22.8-.94,3.52L27.84,82l6.46-2,4.58,5,2.46,2.68.94-3.52,6.11-22.79.52-1.93L47,58.92,33.87,55.41l-1.93-.52Z"
          />
          <path
            fill="#46b6ff"
            d="M48,66.65a4.66,4.66,0,0,1-2.11-.51L41.76,64H37.07A4.45,4.45,0,0,1,33.33,62l-2.64-3.85L26.51,56a4.34,4.34,0,0,1-2.37-3.45L23.56,48,20.9,44.09a4.37,4.37,0,0,1-.54-4.16L22,35.54l-.59-4.66A4.48,4.48,0,0,1,22.93,27l3.56-3,1.64-4.38a4.38,4.38,0,0,1,3.11-2.79l4.58-1.11,3.5-3.13a4.48,4.48,0,0,1,3-1.14,4.08,4.08,0,0,1,1.05.13L47.9,12.6l4.58-1.11a4.28,4.28,0,0,1,1.09-.14,4.57,4.57,0,0,1,3,1.12L60,15.57l4.55,1.09a4.49,4.49,0,0,1,3.14,2.74l1.73,4.36,3.49,3.09a5,5,0,0,1,1.57,4L74,35.6,75.63,40a4.51,4.51,0,0,1-.49,4.16L72.46,48l-.54,4.64A4.5,4.5,0,0,1,69.53,56l-4.17,2.17-2.68,3.85A4.67,4.67,0,0,1,59,64H54.28L50.1,66.15A4.36,4.36,0,0,1,48,66.65Z"
          />
          <path
            fill="#e8eef9"
            d="M53.57,12.35a3.56,3.56,0,0,1,2.32.88l3.67,3.25,4.79,1.15a3.53,3.53,0,0,1,2.44,2.13l1.81,4.59,3.68,3.25a4,4,0,0,1,1.24,3.24l-.58,4.89,1.76,4.6a3.48,3.48,0,0,1-.39,3.24l-2.82,4-.57,4.88a3.5,3.5,0,0,1-1.84,2.65l-4.4,2.29-2.82,4A3.63,3.63,0,0,1,59,63H54l-4.4,2.28a3.41,3.41,0,0,1-1.6.39,3.52,3.52,0,0,1-1.64-.4L42,63H37.07a3.48,3.48,0,0,1-2.91-1.49l-2.79-4.07L27,55.15a3.36,3.36,0,0,1-1.84-2.66l-.61-4.89-2.8-4.07a3.41,3.41,0,0,1-.42-3.24L23,35.66l-.62-4.9a3.47,3.47,0,0,1,1.17-3l3.76-3.19,1.73-4.63a3.36,3.36,0,0,1,2.42-2.17l4.81-1.17L40,13.26a3.47,3.47,0,0,1,2.32-.88,3.21,3.21,0,0,1,.81.1l4.79,1.15,4.81-1.17a3.62,3.62,0,0,1,.86-.11m0-2a5.18,5.18,0,0,0-1.36.18l-4.31,1-4.32-1a5.4,5.4,0,0,0-1.28-.15,5.49,5.49,0,0,0-3.66,1.4l-3.3,3-4.32,1a5.41,5.41,0,0,0-3.84,3.46l-1.53,4.1-3.37,2.85A5.48,5.48,0,0,0,20.42,31V31L21,35.42l-1.56,4.16a5.42,5.42,0,0,0,.67,5.11l2.51,3.64.55,4.39A5.37,5.37,0,0,0,26,56.92l4,2,2.52,3.66A5.4,5.4,0,0,0,37.06,65h4.45l4,2a5.59,5.59,0,0,0,2.56.62A5.42,5.42,0,0,0,50.58,67l4-2H59a5.6,5.6,0,0,0,4.5-2.31l0,0v0L66,59l4-2.06a5.47,5.47,0,0,0,2.9-4.15l.52-4.43L76,44.72a5.52,5.52,0,0,0,.61-5.11L75,35.48l.51-4.41v-.15a6,6,0,0,0-1.88-4.79l-3.34-3L68.65,19a5.5,5.5,0,0,0-3.83-3.33l-4.31-1-3.3-2.92a5.6,5.6,0,0,0-3.64-1.38Z"
          />
          <circle fill="#008ce7" cx="48" cy="39" r="18.21" />
          <polygon
            fill="#fff"
            points="44.79 47.41 37.49 40.24 39.88 37.81 44.77 42.63 56.03 31.41 58.43 33.82 44.79 47.41"
          />
        </g>
      </g>
    </svg>
  );
};

AwardCheckIcon.propTypes = {
  className: PropTypes.string,
};

AwardCheckIcon.defaultProps = { className: '' };

export default memo(AwardCheckIcon);

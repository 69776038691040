import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronIcon } from '@components';
import { SocialLinks } from '../social-links';
import './footer.scss';

const socials = [
  {
    id: 0,
    linkClassName: 'btn-facebook',
    url: 'https://www.facebook.com/',
    iconClassName: 'facebook',
    text: 'Facebook',
  },
  {
    id: 1,
    linkClassName: 'btn-instagram',
    url: 'https://www.instagram.com/',
    iconClassName: 'instagram',
    text: 'Instagram',
  },
  {
    id: 2,
    linkClassName: 'btn-twitter',
    url: 'https://www.twitter.com/',
    iconClassName: 'twitter',
    text: 'Twitter',
  },
  {
    id: 3,
    linkClassName: 'btn-linkedin',
    url: 'https://www.linkedin.com/',
    iconClassName: 'linkedin',
    text: 'LinkedIn',
  },
  {
    id: 4,
    linkClassName: 'btn-youtube',
    url: 'https://www.youtube.com/',
    iconClassName: 'youtube',
    text: 'Youtube',
  },
];

const Footer = (props) => {
  const [buyOpen, toggleBuy] = useState(false);
  const [citiesOpen, toggleCities] = useState(false);

  const { children } = props;
  return (
    <footer className="on-md-footer on-classic">
      <div className="on-mls-declaimer-container">
        <div className="on-declaimer on-mg-container">
          <div className="on-mls-logo">
            <img
              alt=""
              src="https://cdn.chime.me/image/fs01/agnentinfo/20170421/4/w2000_original_16685641681349614.jpeg"
            />
          </div>
          <div>
            The data is deemed reliable, but is not guaranteed accurate by the MLS or NTREIS. Consumers may not
            reproduce or redistribute NTREIS data since the use of this data is only for people viewing this web site.
          </div>
        </div>
        <div className="on-declaimer on-mg-container">
          <div>
            IDX information is provided exclusively for consumers’ personal, non-commercial use and that it may not be
            used for any purpose other than to identify prospective properties consumers may be interested in
            purchasing. Information deemed reliable but not guaranteed to be accurate. Listing information updated
            daily.
          </div>
        </div>
      </div>
      <div className="on-footer-container" style={{ backgroundColor: 'rgb(37, 47, 55)', color: 'rgb(255, 255, 255)' }}>
        <div className="on-mg-container">
          <div className="on-main-content">
            <div className="on-left-content">
              <p className="on-menu-title">SITE MAP</p>
              <ul
                className="on-main-menu"
                style={{
                  fontFamily: 'var(--font-normal)',
                  fontSize: '1em',
                  '-originMenuColor': '#c8c8c8',
                  '-hoverMenuColor': '#fff',
                }}>
                <li className="on-menu-item">
                  <a
                    href="https://www.dfwrealestateinfo.com/"
                    target="_self"
                    rel=""
                    className="on-footer-menu"
                    style={{
                      fontFamily: 'var(--font-normal)',
                      fontSize: '1em',
                      '-originMenuColor': '#c8c8c8',
                      '-hoverMenuColor': '#fff',
                    }}>
                    <span title="HOME" className="on-menu-label on-uppercase">
                      Home
                    </span>
                  </a>
                </li>
                <li className="on-menu-item">
                  <a
                    className="on-footer-menu on-hasChild"
                    onClick={() => toggleBuy(!buyOpen)}
                    style={{
                      fontFamily: 'var(--font-normal)',
                      fontSize: '1em',
                      '-originMenuColor': '#c8c8c8',
                      '-hoverMenuColor': '#fff',
                    }}>
                    <span title="BUY" className="on-menu-label on-uppercase">
                      BUY
                    </span>
                    <ChevronIcon className={`chevron-icon-component ${buyOpen ? 'up' : ''}`} />
                  </a>
                  <ol
                    className="on-footer-submenu"
                    style={{
                      fontFamily: 'var(--font-normal)',
                      fontSize: '0.857143em',
                      '-originSubColor': '#a0a0a0',
                      '-hoverSubColor': '#c8c8c8',
                      display: buyOpen ? 'block' : 'none',
                    }}>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/listing"
                        target="_self"
                        rel=""
                        title="ALL LISTINGS"
                        className="on-submenu-text on-uppercase">
                        All Listings
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/sold-listing"
                        target="_self"
                        rel=""
                        title="SOLD LISTINGS"
                        className="on-submenu-text on-uppercase">
                        Sold Listings
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/featured-listing"
                        target="_self"
                        rel=""
                        title="FEATURED LISTINGS"
                        className="on-submenu-text on-uppercase">
                        Featured Listings
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="on-menu-item">
                  <a
                    href="https://www.dfwrealestateinfo.com/sell"
                    target="_self"
                    rel=""
                    className="on-footer-menu"
                    style={{
                      fontFamily: 'var(--font-normal)',
                      fontSize: '1em',
                      '-originMenuColor': '#c8c8c8',
                      '-hoverMenuColor': '#fff',
                    }}>
                    <span title="SELL" className="on-menu-label on-uppercase">
                      Sell
                    </span>
                  </a>
                </li>
                <li className="on-menu-item">
                  <a
                    href="https://www.dfwrealestateinfo.com/evaluation"
                    target="_self"
                    rel=""
                    className="on-footer-menu"
                    style={{
                      fontFamily: 'var(--font-normal)',
                      fontSize: '1em',
                      '-originMenuColor': '#c8c8c8',
                      '-hoverMenuColor': '#fff',
                    }}>
                    <span title="HOME VALUATION" className="on-menu-label on-uppercase">
                      Home Valuation
                    </span>
                  </a>
                </li>
                <li className="on-menu-item">
                  <a
                    className="on-footer-menu on-hasChild"
                    onClick={() => toggleCities(!citiesOpen)}
                    style={{
                      fontFamily: 'var(--font-normal)',

                      fontSize: '1em',
                      '-originMenuColor': '#c8c8c8',
                      '-hoverMenuColor': '#fff',
                    }}>
                    <span title="CITIES" className="on-menu-label on-uppercase">
                      Cities
                    </span>
                    <ChevronIcon className={`chevron-icon-component ${citiesOpen ? 'up' : ''}`} />
                  </a>
                  <ol
                    className="on-footer-submenu"
                    style={{
                      fontFamily: 'var(--font-normal)',
                      fontSize: '0.857143em',
                      '-originSubColor': '#a0a0a0',
                      '-hoverSubColor': '#c8c8c8',
                      display: citiesOpen ? 'block' : 'none',
                    }}>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079109/Aledo"
                        target="_self"
                        rel=""
                        title="ALEDO"
                        className="on-submenu-text on-uppercase">
                        Aledo
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079110/Alvarado"
                        target="_self"
                        rel=""
                        title="ALVARADO"
                        className="on-submenu-text on-uppercase">
                        Alvarado
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079111/Arlington"
                        target="_self"
                        rel=""
                        title="ARLINGTON"
                        className="on-submenu-text on-uppercase">
                        Arlington
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079112/Azle"
                        target="_self"
                        rel=""
                        title="AZLE"
                        className="on-submenu-text on-uppercase">
                        Azle
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079113/Bedford"
                        target="_self"
                        rel=""
                        title="BEDFORD"
                        className="on-submenu-text on-uppercase">
                        Bedford
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079114/Benbrook"
                        target="_self"
                        rel=""
                        title="BENBROOK"
                        className="on-submenu-text on-uppercase">
                        Benbrook
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079115/Burleson"
                        target="_self"
                        rel=""
                        title="BURLESON"
                        className="on-submenu-text on-uppercase">
                        Burleson
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079116/Cedar-Hill"
                        target="_self"
                        rel=""
                        title="CEDAR HILL"
                        className="on-submenu-text on-uppercase">
                        Cedar Hill
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079117/Cleburne"
                        target="_self"
                        rel=""
                        title="CLEBURNE"
                        className="on-submenu-text on-uppercase">
                        Cleburne
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079118/Colleyville"
                        target="_self"
                        rel=""
                        title="COLLEYVILLE"
                        className="on-submenu-text on-uppercase">
                        Colleyville
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079119/Crowley"
                        target="_self"
                        rel=""
                        title="CROWLEY"
                        className="on-submenu-text on-uppercase">
                        Crowley
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079120/Dallas"
                        target="_self"
                        rel=""
                        title="DALLAS"
                        className="on-submenu-text on-uppercase">
                        Dallas
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079121/Dalworthington-Gardens"
                        target="_self"
                        rel=""
                        title="DALWORTHINGTON GARDENS"
                        className="on-submenu-text on-uppercase">
                        Dalworthington Gardens
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079122/Desoto"
                        target="_self"
                        rel=""
                        title="DESOTO"
                        className="on-submenu-text on-uppercase">
                        Desoto
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079123/Duncanville"
                        target="_self"
                        rel=""
                        title="DUNCANVILLE"
                        className="on-submenu-text on-uppercase">
                        Duncanville
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079124/Euless"
                        target="_self"
                        rel=""
                        title="EULESS"
                        className="on-submenu-text on-uppercase">
                        Euless
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079125/Fort-Worth"
                        target="_self"
                        rel=""
                        title="FORT WORTH"
                        className="on-submenu-text on-uppercase">
                        Fort Worth
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079126/Grand-Prairie"
                        target="_self"
                        rel=""
                        title="GRAND PRAIRIE"
                        className="on-submenu-text on-uppercase">
                        Grand Prairie
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079127/Grapevine"
                        target="_self"
                        rel=""
                        title="GRAPEVINE"
                        className="on-submenu-text on-uppercase">
                        Grapevine
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079128/Haslet"
                        target="_self"
                        rel=""
                        title="HASLET"
                        className="on-submenu-text on-uppercase">
                        Haslet
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079129/Hurst"
                        target="_self"
                        rel=""
                        title="HURST"
                        className="on-submenu-text on-uppercase">
                        Hurst
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079130/Irving"
                        target="_self"
                        rel=""
                        title="IRVING"
                        className="on-submenu-text on-uppercase">
                        Irving
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079131/Joshua"
                        target="_self"
                        rel=""
                        title="JOSHUA"
                        className="on-submenu-text on-uppercase">
                        Joshua
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079132/Kennedale"
                        target="_self"
                        rel=""
                        title="KENNEDALE"
                        className="on-submenu-text on-uppercase">
                        Kennedale
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079133/Keller"
                        target="_self"
                        rel=""
                        title="KELLER"
                        className="on-submenu-text on-uppercase">
                        Keller
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079134/Lancaster"
                        target="_self"
                        rel=""
                        title="LANCASTER"
                        className="on-submenu-text on-uppercase">
                        Lancaster
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079135/Mansfield"
                        target="_self"
                        rel=""
                        title="MANSFIELD"
                        className="on-submenu-text on-uppercase">
                        Mansfield
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079136/Midlothian"
                        target="_self"
                        rel=""
                        title="MIDLOTHIAN"
                        className="on-submenu-text on-uppercase">
                        Midlothian
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079137/North-Richland-Hills"
                        target="_self"
                        rel=""
                        title="NORTH RICHLAND HILLS"
                        className="on-submenu-text on-uppercase">
                        North Richland Hills
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079138/Pantego"
                        target="_self"
                        rel=""
                        title="PANTEGO"
                        className="on-submenu-text on-uppercase">
                        Pantego
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079139/Red-Oak"
                        target="_self"
                        rel=""
                        title="RED OAK"
                        className="on-submenu-text on-uppercase">
                        Red Oak
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079140/Roanoke"
                        target="_self"
                        rel=""
                        title="ROANOKE"
                        className="on-submenu-text on-uppercase">
                        Roanoke
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079141/Saginaw"
                        target="_self"
                        rel=""
                        title="SAGINAW"
                        className="on-submenu-text on-uppercase">
                        Saginaw
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079142/Southlake"
                        target="_self"
                        rel=""
                        title="SOUTHLAKE"
                        className="on-submenu-text on-uppercase">
                        Southlake
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079143/Venus"
                        target="_self"
                        rel=""
                        title="VENUS"
                        className="on-submenu-text on-uppercase">
                        Venus
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079144/Waxahachie"
                        target="_self"
                        rel=""
                        title="WAXAHACHIE"
                        className="on-submenu-text on-uppercase">
                        Waxahachie
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079145/Weatherford"
                        target="_self"
                        rel=""
                        title="WEATHERFORD"
                        className="on-submenu-text on-uppercase">
                        Weatherford
                      </a>
                    </li>
                    <li className="on-sub-menu">
                      <a
                        href="https://www.dfwrealestateinfo.com/neighborhoods/12079146/White-Settlement"
                        target="_self"
                        rel=""
                        title="WHITE SETTLEMENT"
                        className="on-submenu-text on-uppercase">
                        White Settlement
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="on-menu-item">
                  <a
                    href="https://www.dfwrealestateinfo.com/blog"
                    target="_self"
                    rel=""
                    className="on-footer-menu"
                    style={{
                      fontFamily: 'var(--font-normal)',
                      fontSize: '1em',
                      '-originMenuColor': '#c8c8c8',
                      '-hoverMenuColor': '#fff',
                    }}>
                    <span title="BLOGS" className="on-menu-label on-uppercase">
                      Blogs
                    </span>
                  </a>
                </li>
                <li className="on-menu-item">
                  <a
                    href="https://www.zillow.com/profile/DonLawyer/"
                    target="_blank"
                    rel="noreferrer"
                    className="on-footer-menu"
                    style={{
                      fontFamily: 'var(--font-normal)',
                      fontSize: '1em',
                      '-originMenuColor': '#c8c8c8',
                      '-hoverMenuColor': '#fff',
                    }}>
                    <span title="REVIEWS" className="on-menu-label on-uppercase">
                      Reviews
                    </span>
                  </a>
                </li>
                <li className="on-menu-item">
                  <a
                    href="https://www.dfwrealestateinfo.com/about"
                    target="_self"
                    rel=""
                    className="on-footer-menu"
                    style={{
                      fontFamily: 'var(--font-normal)',
                      fontSize: '1em',
                      '-originMenuColor': '#c8c8c8',
                      '-hoverMenuColor': '#fff',
                    }}>
                    <span title="ABOUT" className="on-menu-label on-uppercase">
                      About
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="on-right-content">
              <div className="on-site-logo">
                <img
                  alt=""
                  className="on-logo-item"
                  src="https://cdn.chime.me/image/fs01/sitebuild/20180830/21/h82_original_20669463033193921.png"
                  style={{ height: '1.2em' }}
                />
              </div>

              <p className="on-detail-info" />
              <p className="on-detail-info" />
              <div className="on-no-mls-disclaimer">
                <div className="on-claimer">
                  <p>
                    <span style={{ color: '#aaaaaa' }}>
                      <a
                        style={{ color: '#aaaaaa' }}
                        href="//static.chimeroi.com/servicetool/174fa69a-6bd8-49f3-98d7-b50a102e9099/IABS-MIchaelYarritoSupervisor.pdf"
                        target="_blank"
                        rel="noreferrer">
                        IABS - MIchael Yarrito Supervisor
                      </a>
                    </span>
                  </p>
                </div>
                <div className="on-claimer">
                  <p>
                    <span style={{ color: '#aaaaaa' }}>
                      <a
                        style={{ color: '#aaaaaa' }}
                        href="//static.chimeroi.com/servicetool/6ac7123e-08e8-48e0-8833-30ca67073802/TexasRealEstateConsumerProtectionNotice.pdf"
                        target="_blank"
                        rel="noreferrer">
                        Texas Real Estate Consumer Protection Notice
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = { children: null };

export default memo(Footer);

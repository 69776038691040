import { DesktopNav, MobileNav } from '@components';
import { menuItems } from '@constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import './header.scss';

const Header = (props) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleNav = (e) => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const { children } = props;
  return (
    <header className="on-md-header on-classic" style={{ '-headerLogoFontsize': '30px' }}>
      <div
        className="on-header-container"
        style={{
          color: 'rgb(255, 255, 255)',
          '-headerBgcolor': '#252f37',
          fontFamily: 'var(--font-normal)',
          fontSize: '13px',
        }}>
        <div className="on-header-bg" style={{ background: 'rgb(37, 47, 55)' }} />
        <a href="https://www.dfwrealestateinfo.com/" aria-label="" className="on-logo">
          <img
            alt=""
            src="https://cdn.chime.me/image/fs01/sitebuild/20180830/21/h96_original_20669462780699747.png"
            style={{ height: '1.5em' }}
          />
        </a>

        <div
          className="on-nav-container"
          style={{
            color: 'rgb(255, 255, 255)',
            '-headerBgcolor': '#252f37',
            fontFamily: 'var(--font-normal)',
            fontSize: '13px',
          }}>
          <i className="on-iconfont on-icon-close-light" />
          <ul className="on-head-menu">
            <li className="on-menu-item on-active">
              <a href="https://www.dfwrealestateinfo.com/" target="_self" rel="" className="on-uppercase">
                Home
              </a>
            </li>
            <li className="on-menu-item on-has-child">
              <a className="on-has-child-a on-uppercase">BUY</a>
              <div className="on-wrapper">
                <ol className="on-submenu on-hidden-scrollbar">
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/listing" target="_self" rel="">
                      All Listings
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/sold-listing" target="_self" rel="">
                      Sold Listings
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/featured-listing" target="_self" rel="">
                      Featured Listings
                    </a>
                  </li>
                </ol>
              </div>
            </li>
            <li className="on-menu-item">
              <a href="https://www.dfwrealestateinfo.com/sell" target="_self" rel="" className="on-uppercase">
                Sell
              </a>
            </li>
            <li className="on-menu-item">
              <a href="https://www.dfwrealestateinfo.com/evaluation" target="_self" rel="" className="on-uppercase">
                Home Valuation
              </a>
            </li>
            <li className="on-menu-item on-has-child">
              <a className="on-has-child-a on-uppercase">
                Cities
                <i className="on-iconfont on-icon-arrow-down" style={{ display: 'none' }} />
              </a>
              <div className="on-wrapper">
                <ol className="on-submenu on-hidden-scrollbar">
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079109/Aledo" target="_self" rel="">
                      Aledo
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079110/Alvarado" target="_self" rel="">
                      Alvarado
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079111/Arlington" target="_self" rel="">
                      Arlington
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079112/Azle" target="_self" rel="">
                      Azle
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079113/Bedford" target="_self" rel="">
                      Bedford
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079114/Benbrook" target="_self" rel="">
                      Benbrook
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079115/Burleson" target="_self" rel="">
                      Burleson
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079116/Cedar-Hill" target="_self" rel="">
                      Cedar Hill
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079117/Cleburne" target="_self" rel="">
                      Cleburne
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a
                      href="https://www.dfwrealestateinfo.com/neighborhoods/12079118/Colleyville"
                      target="_self"
                      rel="">
                      Colleyville
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079119/Crowley" target="_self" rel="">
                      Crowley
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079120/Dallas" target="_self" rel="">
                      Dallas
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a
                      href="https://www.dfwrealestateinfo.com/neighborhoods/12079121/Dalworthington-Gardens"
                      target="_self"
                      rel="">
                      Dalworthington Gardens
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079122/Desoto" target="_self" rel="">
                      Desoto
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a
                      href="https://www.dfwrealestateinfo.com/neighborhoods/12079123/Duncanville"
                      target="_self"
                      rel="">
                      Duncanville
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079124/Euless" target="_self" rel="">
                      Euless
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079125/Fort-Worth" target="_self" rel="">
                      Fort Worth
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a
                      href="https://www.dfwrealestateinfo.com/neighborhoods/12079126/Grand-Prairie"
                      target="_self"
                      rel="">
                      Grand Prairie
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079127/Grapevine" target="_self" rel="">
                      Grapevine
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079128/Haslet" target="_self" rel="">
                      Haslet
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079129/Hurst" target="_self" rel="">
                      Hurst
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079130/Irving" target="_self" rel="">
                      Irving
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079131/Joshua" target="_self" rel="">
                      Joshua
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079132/Kennedale" target="_self" rel="">
                      Kennedale
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079133/Keller" target="_self" rel="">
                      Keller
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079134/Lancaster" target="_self" rel="">
                      Lancaster
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079135/Mansfield" target="_self" rel="">
                      Mansfield
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079136/Midlothian" target="_self" rel="">
                      Midlothian
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a
                      href="https://www.dfwrealestateinfo.com/neighborhoods/12079137/North-Richland-Hills"
                      target="_self"
                      rel="">
                      North Richland Hills
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079138/Pantego" target="_self" rel="">
                      Pantego
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079139/Red-Oak" target="_self" rel="">
                      Red Oak
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079140/Roanoke" target="_self" rel="">
                      Roanoke
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079141/Saginaw" target="_self" rel="">
                      Saginaw
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079142/Southlake" target="_self" rel="">
                      Southlake
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079143/Venus" target="_self" rel="">
                      Venus
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/neighborhoods/12079144/Waxahachie" target="_self" rel="">
                      Waxahachie
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a
                      href="https://www.dfwrealestateinfo.com/neighborhoods/12079145/Weatherford"
                      target="_self"
                      rel="">
                      Weatherford
                    </a>
                  </li>
                  <li className="on-menu-item">
                    <a
                      href="https://www.dfwrealestateinfo.com/neighborhoods/12079146/White-Settlement"
                      target="_self"
                      rel="">
                      White Settlement
                    </a>
                  </li>
                </ol>
              </div>
            </li>
            <li className="on-menu-item">
              <a href="https://www.dfwrealestateinfo.com/blog" target="_self" rel="" className="on-uppercase">
                Blogs
              </a>
            </li>
            <li className="on-menu-item">
              <a
                href="https://www.zillow.com/profile/DonLawyer/"
                target="_blank"
                rel="noreferrer"
                className="on-uppercase">
                Reviews
              </a>
            </li>
            <li className="on-menu-item">
              <a href="https://www.dfwrealestateinfo.com/about" target="_self" rel="" className="on-uppercase">
                About
              </a>
            </li>
            <li className="on-menu-item on-more">
              <a aria-label="more">MORE</a>
              <div className="on-submenu-container">
                <div className="on-wrapper">
                  <ol className="on-submenu on-hidden-scrollbar" />
                </div>
              </div>
            </li>
          </ul>

          <ul className="on-head-account">
            <li className="on-menu-item on-has-child on-account-icon-container">
              {/* <a className="on-iconfont on-icon-acount-book" /> */}
              <div className="on-wrapper">
                <ol className="on-submenu">
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/profile?type=Favorites">My Favorites</a>
                  </li>
                  <li className="on-menu-item">
                    <a href="https://www.dfwrealestateinfo.com/profile?type=Searches">My Searches</a>
                  </li>
                </ol>
              </div>
            </li>
          </ul>
          <div className="on-avatar" style={{ display: 'none' }}>
            <div className="on-img-box">
              <div className="on-loading-box">
                <div className="on-mg-loading" />
              </div>
              <div className="on-img-content" style={{ zIndex: '-1' }}>
                <img alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="on-nav-mask" />
        <div className="on-nav-mask-dark" style={{ display: 'none' }} />

        <div className="on-mobile-menu-icon">
          <FontAwesomeIcon
            icon="bars"
            className={cs('on-hamburger-menu', 'd-inline', 'd-md-none')}
            onClick={toggleNav}
          />
        </div>
      </div>

      <MobileNav items={menuItems} className="on-mobile-menu" isOpen={mobileNavOpen} toggleNav={toggleNav} />
    </header>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = { children: null };

export default memo(Header);

import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ContactFormSuccess = (props) => {
  const { closeForm } = props;
  return (
    <div className="modal-body align-center">
      <h1>Thank you!</h1>
      <p>We received your inquiry and will contact you shortly.</p>
      <button
        type="button"
        className="button thankyou-btn"
        id="contact-form-submit-btn"
        data-submit="true"
        onClick={closeForm}>
        Close
      </button>
    </div>
  );
};

ContactFormSuccess.propTypes = {
  closeForm: PropTypes.func,
};

ContactFormSuccess.defaultProps = { closeForm: () => {} };

export default memo(ContactFormSuccess);

import { Header } from '@components';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import './header-container.scss';

const HeaderContainer = (props) => {
  const { children } = props;
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleNav = (e) => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return <Header {...props} />;
};

HeaderContainer.propTypes = {
  children: PropTypes.node,
};

HeaderContainer.defaultProps = { children: null };

export default memo(HeaderContainer);

import { React, memo } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        ...ImageQueryFragment
      }
    `}
    render={(data) => {
      const { className, style, alt, filename } = props;
      const searchFilename = filename.replace('.jpg', '');
      const image = data?.images?.edges?.find.find((n) => n.node.relativePath.includes(searchFilename));
      if (!image) {
        console.log(`IMAGE NOT FOUND ${searchFilename}`);
        return null;
      }

      if (searchFilename) {
        return <Img className={className} style={style} alt={alt} fluid={image.node.childImageSharp.fluid} />;
      }
      return <div className={className} style={style} />;
    }}
  />
);

Image.propTypes = {
  alt: PropTypes.string,
  filename: PropTypes.string,

  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any).isRequired,
};

Image.defaultProps = {
  alt: '',
  filename: '',
  className: '',
};

export default memo(Image);

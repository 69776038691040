import React, { memo } from 'react';
import './how-it-works-container.scss';

import { HowItWorks, Step } from '@components';

import useSiteMetadata from '../../../api/graphql/siteMetadata';

import { ContractIcon, MoneyHouseIcon, KeysIcon, DreamHouseIcon, AwardCheckIcon } from '../../../images/icons';

const HowItWorksContainer = (props) => {
  const { title } = useSiteMetadata();

  const children = (
    <>
      <Step
        icon={<AwardCheckIcon className="award-check-icon" />}
        title="Get approved"
        description="HomeLight Home Loans will verify your income, assets, and purchasing power so that they’re ready to make the strongest offer possible — one that’s all cash."
      />
      <Step
        icon={<DreamHouseIcon className="dream-home-icon" />}
        title="Find your dream home"
        description={`This is the fun part: You tour homes with ${title} until you find the right one.`}
      />
      <Step
        icon={<MoneyHouseIcon className="dream-home-money-icon" />}
        title="Make an all-cash offer"
        description="HomeLight uses their funds to make an all-cash offer on the house on your behalf and close in 8 days. You don’t need to move a muscle."
      />
      <Step
        icon={<ContractIcon className="document-icon" />}
        title="The offer is accepted"
        description="You do the celebrating and HomeLight does the buying. They purchase the home and hold it while you secure financing."
      />
      <Step
        icon={<KeysIcon className="keys-icon" />}
        title="Take ownership and move in"
        description="As soon as your loan closes, HomeLight sells the home to you at the same price they purchased it plus a small program fee as low as 1%-1.5% of the home purchase price.** Move on in!"
      />
    </>
  );

  return <HowItWorks title="How HomeLight Cash Offer works">{children}</HowItWorks>;
};

export default memo(HowItWorksContainer);
